.fracture-image > img {
  width: 250px;
  height: 250px;
}
.fracture-heading {
  font-size: 36px;
  margin-bottom: 5vh;
  border-bottom: 3px solid;
}
.fracture-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.fracture-main {
  display: flex;
  flex-direction: row;
}
/* .disease-desc {
  width: 50%;
} */
@media only screen and (max-width: 1200px) {
  .fracture-main {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1200px) {
  .disease-desc {
    width: 50%;
  }
}
