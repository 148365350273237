* {
  margin: 0;
  padding: 0;
}

.profile-box {
  display: flex;
  flex-direction: column;
  padding: 5vh 10vw 5vh 10vw;
  position: relative;
}
.profile-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.heading-name {
  font-size: 30px;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
  color: #666;
  margin-top: 10px;
  margin-bottom: 20px;
}

.path {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  border-bottom: 2px solid rgb(104, 50, 96);
  display: flex;
  align-items: center;
  padding: 0 0 10px 30px;
  width: 40vw;
  margin-bottom: 100px;
  color: rgb(154, 69, 129);
}

.path > a {
  color: #333333;
  background-color: transparent;
  text-decoration: none;
}

.content-box {
  display: flex;
  flex-direction: column;
  gap: 10vh;
}

.detail-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.picbox {
  width: 40%;

  min-width: 200px;
  min-height: 200px;
  max-width: 400px;
  max-width: 400px;
}

.info-box {
  width: 60%;
}

.info-box > * {
  margin-left: 40px;
}

.info-head {
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
}

.p-name {
  color: rgb(154, 69, 129);
  font-size: 45px;
}

.info-body {
  margin-left: 60px;
  display: flex;
  flex-direction: column;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 10px 0;
  border-bottom: 2px solid rgb(104, 50, 96);
}

.left {
  width: 40%;
}

.right {
  width: 60%;
}

.left {
  font-weight: 600;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.picbox > img {
  /* width: fit-content; */
}

.profile-heading {
  display: flex;
  font-size: 25px;
  color: rgb(70, 62, 59);
  font-weight: 500;
}

@media only screen and (max-width: 1300px) {
  .picbox {
    /* width: auto; */
  }

  .p-name {
    font-size: 18px;
  }

  .p-qual {
    font-size: 14px;
  }

  .info-body {
    font-size: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .detail-box {
    justify-content: center;
    flex-wrap: nowrap;
  }
  .picbox {
    width: auto;
  }
  .picbox > img {
    width: 250px;
  }
}

@media only screen and (max-width: 450px) {
  .detail-box {
    flex-wrap: wrap;
  }
  .profile-box-main {
    padding: 2vh;
  }

  .first-profile,
  .second-profile {
    width: 50%;
    text-align: center;
  }

  .profile-box-main img {
    width: 45vw;
  }

  .profile-box-main {
    gap: 10px;
  }

  .first-profile > a,
  .second-profile > a {
    font-size: 11px;
    margin-left: unset;
    text-decoration: none;
    color: rgb(104, 50, 96);
  }

  .brief {
    font-size: 9px;
    font-style: italic;
    text-align: center;
  }

  .doc-name {
    font-size: 13px;
  }

  .first-profile,
  .second-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .qual,
  .brief {
    font-size: 9px;
  }

  .second-profile > div {
    margin: unset;
  }

  .path {
    font-size: 13px;
    width: auto;
    margin-bottom: 40px;
  }

  .picbox {
    width: auto;
  }

  .picbox > img {
    width: 100%;
  }

  .info-head,
  .info-body {
    margin-left: unset;
  }

  .left {
    width: 30%;
  }

  .p-name {
    font-size: 18px;
  }

  .p-qual {
    font-size: 14px;
  }

  .info-body {
    font-size: 10px;
  }

  .info-box {
    width: 100%;
  }

  .line {
    border-bottom: unset;
  }
}
