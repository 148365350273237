.meet-heading {
    display: flex;
    padding-left: 10vw;
    margin-top: 5vh;
    font-size: 25px;
    color: rgb(70, 62, 59);
    font-weight: 400;
}

.profile-box-main {
    display: flex;
    padding: 5vh 10vh;
    justify-content: space-around;
}

.doc-name {
    color: rgb(154, 69, 129);
    padding-top: 20px;
    font-size: 22px;
    font-weight: 500;
}

.qual {
    font-size: 16px;
}

.brief {
    font-size: 14px;
    font-style: italic;
}

.profile-box-main img {
    border-radius: 30px;
    /* width: 20vw; */
}

.first-profile>div {
    margin-left: 20px;
}

.first-profile>a {
    font-size: 13px;
    margin-left: 20px;
    text-decoration: none;
    color: rgb(104, 50, 96);
}

.second-profile>div {
    margin-left: 20px;
}

.second-profile>a {
    font-size: 13px;
    margin-left: 20px;
    text-decoration: none;
    color: rgb(104, 50, 96);
}

.skin-services,
.ortho-services {
    margin: 0 5vw;
    margin-bottom: 10vh;
    padding-bottom: 5vh;
}

.scroller-heading {
    display: flex;
    margin-top: 5vh;
    margin-left: 10vw;
    margin-bottom: 5vh;
    font-size: 25px;
    color: rgb(70, 62, 59);
    font-weight: 400;
    justify-content: space-between;
}

.viewmore {
    display: flex;
    font-size: 15px;
}

.pic-box>img {
    width: 300px;
    height: 350px;
    object-fit: cover;
}

.pic-box {
    /* border: 2px solid red; */
    padding: 16px;
    display: flex;
    position: relative;
    flex-wrap: wrap;
}

.top-text {
    /* border: 2px solid green; */
    position: absolute;
    width: 300px;
    display: flex;
    justify-content: center;
    top: 316px;
    height: 50px;
    background: rgba(0, 0, 0, 0.6);
}

.pro-image {
    /* border: 2px solid red; */
    box-shadow: 0px 0px 8px 2px;
}

@media only screen and (max-width:850px) {
    .pro-image {
        width: 40vw;
    }

    .profile-box-main {
        display: flex;
        padding: 5vh 5vh;
        justify-content: space-between;
    }
}

@media only screen and (max-width:600px) {
    .profile-box-main {
        gap: 10px;
        justify-content: space-between;
        padding: 5vw;
    }

    .pic-box>img {
        width: 40vw;
        height: 20vh;
    }

    .top-text {
        position: unset;
        width: 40vw;
        top: 316px;
        height: auto;
        background-color: unset;
    }

    .top-text>span {
        font-size: 12px;
        color: black;
        line-height: 1;
        margin-top: 5px;
    }

    .scroller-heading {
        font-size: 18px;
    }

    .viewmore {
        font-size: 10px;
        width: min-content;
    }

    .meet-heading {
        padding-left: unset;
        font-size: 20px;
        justify-content: center;
    }
}

@media only screen and (max-width:450px) {
    .pic-box>img {
        width: 40vw;
        height: 20vh;
    }

    .skin-services,
    .ortho-services {
        padding-bottom: unset;
        margin-bottom: unset;
    }

    .top-text {
        position: unset;
        width: 40vw;
        top: 316px;
        height: auto;
        background-color: unset;
    }

    .top-text>span {
        font-size: 12px;
        color: black;
        line-height: 1;
        margin-top: 5px;
    }

    .scroller-heading {
        font-size: 18px;
    }

    .viewmore {
        font-size: 10px;
        width: min-content;
    }

    .meet-heading {
        padding-left: unset;
        font-size: 20px;
        justify-content: center;
    }
}