.contact-box{
    display: flex;
    flex-direction: column;
    padding: 5vh 10vw 5vh 10vw;
    position: relative;
}
.main-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10vw;
    /* flex-wrap: wrap; */
}
.map{
    border:0;
    width: 30vw;
    height: 40vh;
}
.cont-details{
    display: flex;
    width: 50%;
    align-items: flex-start;
    
    justify-content: center;
}
.contact-maingrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.grid-content{
    padding: 10px;
}
.contact-form{
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    justify-content: center;
    align-items: center;
}
form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.inputs{
    display: flex;
    justify-content: space-between;
    padding: 5vh 0 3vh 0;
    gap: 10px;
}
.inputs > input {
    width: 22vw;
    height: 40px;
    font-size: large;
    border-radius: 15px;
    border: 2px solid;
    padding-left: 5px;
}
.form-def > form > textarea{
    font-size: large;
    margin-bottom: 3vh;
    resize: none;
    border-radius: 15px;
    padding: 5px;
    border: 2px solid;
}
.submit-button{
    border: none;
    width:150px;
    height: 40px;
    border-radius: 5px;
    background-color:rgb(207, 124, 143);
    
}
.submit-button:focus{
    background-color:rgb(198, 82, 109);
}
.contact-heading{
    font-size: 30px;
}
@media only screen and (max-width:950px) {
    .main-box{
        justify-content: center;
    }
    .map-box{
        /* width: 55%; */
    }
    .map{
        width: 100%;
    }
    .cont-details{
        width: 32%;
        font-size: 11px;
    }
    .inputs{
        width: 100%;
        flex-wrap: wrap;
    }
    .inputs > input{
        width: 100%;
    }
    .inputs input , input::placeholder{
        font-size: 10px;
    }
    .form-def form textarea,textarea::placeholder{
        font-size: 10px;
        width: 100%;
        box-sizing: border-box;
    }
    
}
@media only screen and (max-width:800px) {
    .main-box{
        flex-wrap: wrap;
    }
}


@media only screen and (max-width:450px){
    .main-box{
        flex-direction: column;
    }
    .map{
        width: 80vw;
    }
    .cont-details{
        font-size: 12px;
    }
    .contact-heading {
        font-size: 20px;
        margin: -2px;
    }
    .inputs{
        flex-direction: column;
        gap: 10px;
    }
    .inputs > input{
        width: inherit;
    }
    .contact-form{
        align-items: unset;
    }
    .btn-appoint{
        width: auto;
        margin: 0 60px;
    }
    .contact-maingrid{
        font-size: 9px;
    }
}