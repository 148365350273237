*{
  /* border: 2px solid red; */
  margin: 0;
  padding: 0;
  font-family: Verdana;
  letter-spacing: 0.8px;
  word-spacing: 1px;
  color: rgb(70, 62, 59);
}
#root{
  background: rgb(229, 214, 196);
}