.disease-box {
    padding: 5vh 10vw 5vh 10vw;
}

.treatment-heading,
.disease-heading {
    font-size: 36px;
    margin-bottom: 5vh;
    border-bottom: 3px solid;
}

.disease-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 5vh;
    /* flex-wrap: wrap; */
    justify-content: center;
}
.dis-image{
    padding-bottom: 40px;
}

.treatment-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.treatment-content>* {
    width: 250px;
    height: 300px;
}

.treatment-img {
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 0px 6px -1px;
}

.treatment-img:hover {
    box-shadow: 0px 0px 16px -1px;
}

.treatment-text {
    padding-top: 5px;
}

.treatment-img>img {
    width: 100%;
}
.overview-heading{
    font-size: 24px;
    font-weight: 600;
    /* margin: 10vh 0 30px 0; */
    border-bottom: 2px solid rgb(104, 50, 96);
}
.disease-desc{
    min-width: 400px;
    padding-left: 30px;
}
.overview-content{
    padding-top: 10px;
    padding-bottom: 20px;
}
@media only screen and (max-width:1050px) {
    .disease-content{
        flex-wrap: wrap;
    }
}
@media only screen and (max-width:700px) {
    .disease-desc {
        min-width: unset;
        padding-left: unset;
        flex-wrap: wrap;
    }
    .dis-image {
        width: 80vw;
    }
    .dis-image >img{
        width: inherit;
    }
}