.service-box {
  display: flex;
  flex-direction: column;
  padding: 10vh;
}
.service-heading {
  display: flex;
  font-size: 30px;
  color: rgb(70, 62, 59);
  font-weight: 600;
  border-bottom: 2px solid rgb(104, 50, 96);
  align-self: flex-start;
  padding: 0 100px 1px 0;
  margin-bottom: 5vh;
}
.service-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
/* .service-content-box{
    flex: 1 1 1 100px;
} */
.service-content-box {
  height: 370px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}
.service-content-box > img {
  width: 300px;
  height: 300px;
  border-radius: 15px;
  object-fit: cover;
  /* position: relative; */
}
.service-sub-heading {
  font-size: 24px;
  font-weight: 600;
  margin: 5vh 0 30px 0;
}

.service-content-box > img:hover {
  width: 310px;
  transform-origin: center;
  height: 310px;
}
.service-top-text {
  width: 300px;
  display: flex;
  position: relative;
  justify-content: center;

  /* background: rgba(0, 0, 0, 0.6); */
}
.box1 {
  /* border: 2px solid red; */
  position: relative;
  width: 400px;
  padding: 10px;
  height: 300px;
  box-sizing: border-box;
  display: flex;
  box-sizing: border-box;
}
.colorbox {
  width: 100%;
  height: 100px;
  background: yellow;
}
.box2 {
  /* border: 2px solid green; */
  background-color: black;
  position: absolute;
  width: 380px;
}

@media screen and (max-width: 950px) {
  .service-box {
    padding: 10vh 5vw 10vh 5vw;
  }
  .service-container {
    grid-template-columns: 33% 33% 33%;
  }
  .service-content-box > img {
    width: 18vh;
    height: 18vh;
    border-radius: 15px;
    /* position: relative; */
  }
  .service-content-box {
    height: auto;
    margin-bottom: 30px;
  }
  .service-heading {
    font-size: 24px;

    margin-bottom: 5vh;
  }
  .service-sub-heading {
    margin: 3vh 0 30px 0;
  }
}

@media only screen and (max-width: 600px) {
  .service-top-text > span {
    font-size: 12px;
    text-align: center;
  }
  .service-top-text {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .service-container {
    display: grid;

    grid-template-columns: 50% 50%;
  }
  .service-content-box {
    /* width: 100px; */
    height: auto;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 15px;
    flex-direction: column;
  }
  .service-content-box > img {
    width: 150px;
    height: 150px;
    border-radius: 15px;
    /* position: relative; */
  }
  .service-top-text {
    width: 100%;
  }
  .service-top-text > span {
    /* width: 50%; */
    font-size: 12px;
    padding-top: 3px;
    text-align: center;
    line-height: 10px;
  }
  .service-heading {
    font-size: 24px;
    align-self: center;
    padding: 50px 0 2px 0;
    text-align: center;
    margin-bottom: 5vh;
  }
  .service-box {
    display: flex;
    flex-direction: column;
    padding: unset;
  }
  .service-sub-heading {
    font-size: 18px;
  }
  .service-sub-heading {
    margin: 5vh 0 30px 0;
    text-align: center;
  }
}
