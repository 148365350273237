*{
    margin: 0;
    padding: 0;
}
.header-box{
    min-height: 70px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    background-color: rgb(217, 176, 155);
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding: 0 20px 0 20px ;
}
.header-links{
    display: flex;
    flex-wrap: wrap;
}
.header-links > *{
    padding: 0 10px 0 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    /* border: 1px solid green; */
    height: 100%;
    
}
.header-links>*:hover{
    border-bottom: 2px solid rgb(104, 50, 96);
    ;
}
.header-links>*:active{
    background-color: rgb(104, 50, 96);
}
.header-links{
    height: 50px;
    display: flex;
    align-items: center;
}





@media only screen and (max-width : 850px){
    .header-box{
        flex-direction: column;
        justify-content: center;
        height: unset;
        padding: unset;
    }
    .header-links{
        padding: unset;
    }
    .header-logo{
        padding-top: 10px;
    }
}
@media only screen and (max-width:600px)  {
    .header-box{
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }
    .header-links{
        padding-top: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        height: fit-content;
        justify-content: center;
        padding-bottom: 10px;
    }
}