*{
    margin: 0;
    padding: 0;
}
.footer-box{

    padding: 0 10vw 0 10vw;
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgb(217, 176, 155);

}
.footer-heading{
    height: 70px;
    display: flex;
    background: rgb(217, 176, 155);
    align-items: center;
    border-bottom: 3px solid rgb(104, 50, 96);
    font-size: 30px;
}
.footer-links{
    display: flex;
    height: 20vh;
    padding: 40px 0 80px 0;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}
.location{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.location div{
    display: flex;
    flex-direction: column;
}
.btn-appoint{
    border: 1px solid rgb(119, 138, 185);
    background-color: rgb(119, 138, 185);
    border-radius: 10px;
    width: 130px;
    height: 40px;
    
}
.mail >*{
    text-decoration: none;

}

.footer-copyright{
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    padding: 0 10vw 0 10vw;
    display: flex;
    background-color: rgb(172, 137, 94);
    align-items: center;
    justify-content: space-between;
}
.footer-icons-links{
    display: flex;
}
.footer-text{
    display: flex;
}
.icons-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}
.icons{
    width: 30px;
    height: 30px;
}
.icons:hover{
    transform-origin: center;
    width: 40px;
    height: 40px;
}
.btn-appoint {
    border-radius: 4px;
    background-color: rgb(207, 124, 143);
    border: none;
    text-align: center;
    width: 200px;
    height: 40px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
  }
  
  .btn-appoint span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .btn-appoint span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .btn-appoint:hover span {
    padding-right: 25px;
  }
  
  .btn-appoint:hover span:after {
    opacity: 1;
    right: 0;
  }
  .appoint-layer-box{
    display: flex;
    font-size: large;
    flex-direction: column;
    width: 40vw;
    overflow: hidden;
    background: rgb(217, 176, 155);
  }
  .appoint-layer-box::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

  .appointment-form{
    border-top: 2px solid red;
    width: 40vw;
    /* height: 100vh; */
    display: flex;
    overflow: scroll;
    /* flex-wrap: nowrap; */
  }
  .appointment-form::-webkit-scrollbar{
    display: none;
  }
  .appointment-form form{
    width: 100vw;
    /* height: 100vh; */
    flex-wrap: nowrap;
  }
  .appointment-form .input-field{
    /* border: 2px solid green; */
    display: flex;
    flex-direction: row;
    margin: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(172, 137, 94);
  }
  .appointment-form .input-field input,select {
    width: 250px;
    margin: 5px 20px 5px 0px;
    border: none;
    border-radius: 10px;
    padding-left: 5px;
    height: 30px;
  }
  .appointment-form .input-field textarea{
    border: none;
    border-radius: 10px;
    width: 250px;
    padding: 5px;
    margin: 5px 20px 5px 0px;
    height: 100px
  }
  .input-field{
    border-radius: 10px;
  }
  .input-field label{
    padding-left: 10px;
  }
  .form-submit{
    display: flex;
    justify-content: center;
}
.form-submit button{
    border: none;
    border-radius: 10px;
    width: 120px;
    height: 40px;
}
.close-btn{
    background-color: rgb(252, 175, 175);
    width: 40px;
    border: none;
    border-radius: 10px;
    height: 40px;
}
.close-btn:hover{
    background-color: rgb(255, 29, 29);
}
.close-btn span{
    font-size: 24px;
    color: black;
}
.appointment-form-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #202020;
    padding: 3px;
}
.appointment-form-heading{
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-weight: 900;
    font-size: 24px;
    color: rgb(154, 69, 129);
}
.other-details{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.phone , .mail{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.whatsapp-layer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #202020;
    padding: 3px;
    gap: 20px;
    margin-bottom: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.whatsapp-layer div{
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-weight: 900;
    font-size: 24px;
    color: rgb(154, 69, 129);
}
.layer-box{
    display: flex;
    align-items: center;
    height: 130px;
    border-radius: 15px;
    
}
@media only screen and (max-width : 950px){
    .footer-links{
        flex-direction: column;
        align-items: unset;
    }
    .appoint-layer-box{
        width: 80vw;
    }
    .appointment-form{
        width: 100%;
    }
    .form-def{
        width: 100%;
    }
}
@media only screen and (max-width: 600px){
    /* *{
        font-size: small;
    } */
    .footer-heading{
        font-size: 20px;
    }
    
    .footer-links{
        flex-direction: column;
        font-size: 10px;
    }
    .other-details{
        display: flex;
    flex-direction: column;
    justify-content: center;
    }
    .footer-box{
        font-size: 10px;
    }
    .phone >*{
        padding: 3px;
    }
    .mail > *{
        padding: 3px;
    }
    .appoint-layer-box{
        width: 100vw;
        height: 100vh;
    }
    .appointment-form{
        width: 100vw;
        font-size: small;
    }
}
@media only screen and (max-width:450px){
    .whatsapp-layer{
        width: 100%;
        padding: unset;
        /* border-radius: 15px; */
       
    }
    .whatsapp-layer> div{
        font-size: 13px;
    }
    .layer-box{
        height: 130px;
        width: 80vw;
    }
    .close-btn{
        margin: 8px 8px 8px 0;
    }
}